import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Image from "gatsby-image";
import { has } from "lodash";
import { css } from "@emotion/core";
import { MdKeyboardArrowRight } from "react-icons/md";
import { OutboundLink } from "gatsby-plugin-google-analytics";
export default function Footer() {
  const { logo, pack } = useStaticQuery(graphql`
    {
      pack: file(absolutePath: { regex: "/footer-products-2024.png/" }) {
        s: childImageSharp {
          fluid {
            presentationWidth
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/winalot-logofotter.png/" }) {
        s: childImageSharp {
          fluid {
            presentationWidth
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div
      className="bg-primary border-t-8 border-accent"
      css={css`
        /* @md */
          background-color: #004030;
          background: #004030;
          );
          @media (max-width: 500px) {
            background-color: #004030;
            background: #004030;
          }
      `}
    >
      <div className="container">
        <div className="row  py-10">
          <div className="col w-full md-w-100 text-center">
            <div
              css={css`
                max-width: 100px;
                margin: -48px auto 0 auto;
              `}
            >
              {has(logo, "s.fluid") && (
                <OutboundLink
                  href="https://bit.ly/3eRimzY"
                  target="_blank"
                >
                  <Image
                    style={{
                      maxWidth: logo.s.fluid.presentationWidth,
                    }}
                    fluid={logo.s.fluid}
                  />
                </OutboundLink>
              )}
            </div>
            <OutboundLink
              href="https://bit.ly/3eRimzY"
              target="_blank"
              className="block"
            >
              {" "}
              {has(pack, "s.fluid") && (
                <Image
                  style={{
                    maxWidth: pack.s.fluid.presentationWidth,
                    top: 35,
                    margin: "0 auto 30px auto",
                    width: "90%",
                  }}
                  fluid={pack.s.fluid}
                />
              )}
            </OutboundLink>
            <OutboundLink
              href="https://bit.ly/3eRimzY"
              target="_blank"
              className="inline-block  bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-3xl py-2 px-20 rounded-full mt-10" css={css`
              color: #004030;
              background-color: #fff
            `}
            >
              Discover More
              <MdKeyboardArrowRight
                className="w-8 h-8 absolute"
                css={css`
                  top: calc(50% - 1rem);
                  right: 1rem;
                  color: #004030;
                `}
              />
            </OutboundLink>
          </div>
        </div>
        <span css={css`
                  color: #fff;
                `}><center>©Reg. Trademark of Société des Produits Nestlé S.A.</center></span><br />
      </div>
    </div>
  );
}
