import React, { useState, useEffect } from "react";
import Image from "gatsby-image";
import has from "lodash/has";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { get } from "lodash";

export default function Listing({ data, title }) {
  const tiles = get(data, "nodes");

  const ITEMS_PER_SLIDE = 5;
  const MOBILE_ITEMS_PER_SLIDE = 4;

  const [{ list, page, next, mobileList, mobileNext }, setState] = useState({
    list: ITEMS_PER_SLIDE,

    mobileList: MOBILE_ITEMS_PER_SLIDE,
    page: 1,
    next: tiles.length > ITEMS_PER_SLIDE,
    nextMobile: tiles.length > MOBILE_ITEMS_PER_SLIDE,
  });

  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    if (page > 1) {
      window.pageYOffset = offsetY;
    }
  }, [page]);

  useEffect(() => {
    setState({
      list,
      mobileList,
      next: list < tiles.length,
      mobileNext: mobileList < tiles.length,
    });
  }, [list]);

  return (
    <div>
      <div className="container py-10">
        <h2 className="font-slab font-bold text-2xl text-primary mb-6">
          {title}
        </h2>{" "}
        <div
          layout
          className={`grid gap-4  grid-cols-2 md:grid-cols-4 lg:grid-cols-5`}
          css={css`
            grid-template-rows: min-content;
          `}
        >
          <div className="hidden lg:contents">
            {tiles[0] &&
              tiles.slice(0, list).map(({ frontmatter: tile }, i) => {
                return (
                  <div
                    key={i}
                    className="rounded bg-white overflow-hidden shadow relative"
                  >
                    {tile.tags &&
                      Array.isArray(tile.tags) &&
                      tile.tags.includes("Class of 19/23") && (
                        <div className="absolute top-0 left-0 z-20 mt-2 ml-2 ">
                          <div className="bg-primary uppercase  whitespace-no-wrap leading-none px-2 py-1 mb-2 rounded-lg text-white font-bench inline-block text-sm">
                            Class of 19/23
                          </div>{" "}
                        </div>
                      )}
                    {tile.tags &&
                      Array.isArray(tile.tags) &&
                      tile.tags.includes("Article") && (
                        <div className="absolute top-0 left-0 z-20 mt-2 ml-2 ">
                          <div className="bg-article uppercase  whitespace-no-wrap leading-none px-2 py-1 mb-2 rounded-lg text-white font-bench inline-block text-sm">
                            Article
                          </div>{" "}
                        </div>
                      )}
                    <Link to={`/${tile.slug}/`}>
                      {has(tile, "thumb.childImageSharp.fluid") && (
                        <Image
                          className="h-40"
                          fluid={tile.thumb.childImageSharp.fluid}
                        />
                      )}
                      <div className="p-4">
                        <h3 className="font-slab font-bold text-primary text-lg">
                          {tile.subtitle}
                        </h3>
                        <p className="opacity-75">{tile.subtitle2}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
          <div className="contents lg:hidden">
            {tiles[0] &&
              tiles.slice(0, mobileList).map(({ frontmatter: tile }, i) => {
                return (
                  <div
                    key={i}
                    className="rounded bg-white overflow-hidden shadow"
                  >
                    {" "}
                    <Link to={`/${tile.slug}/`} className="relative">
                      {tile.tags &&
                        Array.isArray(tile.tags) &&
                        tile.tags.includes("Class of 19/23") && (
                          <div className="absolute top-0 left-0 z-20 mt-2 ml-2 ">
                            <div className="bg-primary whitespace-no-wrap uppercase leading-none px-2 py-1 mb-2 rounded-lg text-white font-bench inline-block text-sm">
                              Class of 19/23
                            </div>{" "}
                          </div>
                        )}
                      {has(tile, "thumb.childImageSharp.fluid") && (
                        <Image
                          className="h-40"
                          fluid={tile.thumb.childImageSharp.fluid}
                        />
                      )}
                      <div className="p-4">
                        <h3 className="font-slab font-bold text-primary text-lg">
                          {tile.subtitle}
                        </h3>
                        <p className="opacity-75">{tile.subtitle2}</p>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="pt-10 text-center">
          {next && (
            <button
              onClick={() => {
                setOffsetY(window.pageYOffset - 100);
                setState({
                  list: list + ITEMS_PER_SLIDE,
                  mobileList: mobileList + MOBILE_ITEMS_PER_SLIDE,
                  page: page + 1,
                  next,
                  mobileNext,
                });
              }}
              className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-3xl py-2 px-20 rounded-full tablet:hidden"
            >
              LOAD MORE DOGS{" "}
              <MdKeyboardArrowDown
                className="w-8 h-8 absolute"
                css={css`
                  top: calc(50% - 1rem);
                  right: 1rem;
                `}
              />
            </button>
          )}
          {mobileNext && (
            <button
              onClick={() => {
                setOffsetY(window.pageYOffset - 100);
                setState({
                  list: list + ITEMS_PER_SLIDE,
                  mobileList: mobileList + MOBILE_ITEMS_PER_SLIDE,
                  page: page + 1,
                  next,
                  mobileNext,
                });
              }}
              className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-3xl py-2 px-20 rounded-full lg:hidden"
            >
              LOAD MORE DOGS{" "}
              <MdKeyboardArrowDown
                className="w-8 h-8 absolute"
                css={css`
                  top: calc(50% - 1rem);
                  right: 1rem;
                `}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
  
}
