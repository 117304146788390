import React from "react";
import Navigation from "./Navigation";
import DesktopMenu from "./Navigation/DesktopMenu";

export default function Navbar({ location }) {
  return (
    <Navigation>
      <DesktopMenu location={location} />
    </Navigation>
  );
}
