import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Slider from "../components/home/Slider";
import { css } from "@emotion/core";
import Listing from "../components/Layout/Listing";
import Footer from "../components/Layout/Footer";
import ReusableTiles from "../components/ReusableTiles";
import PuppyReusableTiles from "../components/ReusableTiles";
import SecondReusableTiles from "../components/SecondReusableTiles";
import ThirdReusableTiles from "../components/ThirdReusableTiles";
import Navigation from "../components/Layout/Navigation";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import MenuControls from "../components/Layout/Navigation/MenuControls";

const IndexPage = ({ data, location }) => (

  <div className="overflow-x-hidden">
    {/* <SEO title="greatbritishdogs.co.uk" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Home"
      keywords={[`great`, `british`, `dogs`]}
    />
    <TopBar />
    <Navigation>
      <MenuControls location={location} />
      <MobileNavigation></MobileNavigation>
    </Navigation>
    <Slider />
    <Navbar location={location}></Navbar>
    <div className="bg-pale-200">
      <ReusableTiles />
    </div>
    <div className="bg-pale-100">
      <Listing title="Newest Dogs" data={data.newest} />
    </div>
    <div className="bg-pale-100" css={css`
                margin-top: -1.5em;
              `}>
      <Listing title="Puppy" data={data.puppy} />
    </div>
    <div className="bg-pale-200" css={css`
                background-color: #e4e1c5;
                padding-bottom: 2.5em;
              `}>
      <SecondReusableTiles />
    </div>
    <div className="bg-pale-100" css={css`
                margin-top: -2.5em;
              `}>
      <Listing title="Adult" data={data.adult} />
    </div>
    <div className="bg-pale-100" css={css`
                margin-top: -2.5em;
              `}>
      {" "}
      <Listing title="Small" data={data.small} />
    </div>
    <div className="bg-pale-200" css={css`
                background-color: #e4e1c5;
                padding-bottom: 2.5em;
              `}>
      <ThirdReusableTiles />
    </div>
    <div className="bg-pale-100" css={css`
                margin-top: -2.5em;
              `}>
      <Listing title="Senior" data={data.senior} />
    </div>
    <div className="bg-pale-100" css={css`
                margin-top: -2.5em;
              `}>
      {" "}
      <Listing title="Class of 19/23" data={data.class2019} />
    </div>
    <Footer />
  </div>
);

export default IndexPage;

export const query = graphql`
  {
    newest: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/storys/" }
        frontmatter: { year: { ne: 2019 } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    puppy: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/storys/" }
        frontmatter: { tags: { in: ["Puppy"] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    adult: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/storys/" }
        frontmatter: { tags: { in: ["Adult"] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    small: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/storys/" }
        frontmatter: { tags: { in: ["Small"] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    senior: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/storys/" }
        frontmatter: { tags: { in: ["Senior"] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    article: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/articles/" }
        frontmatter: { tags: { in: ["Article"] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    class2019: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/storys/" }
        frontmatter: { tags: { in: ["Class of 19/23"] } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          subtitle
          subtitle2
          tags
          thumb {
            publicURL
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
