import React from "react";

import { css } from "@emotion/core";
import { graphql, Link, useStaticQuery } from "gatsby";
import get from "lodash/get";
import has from "lodash/has";
import Image from "gatsby-image";
import cn from "classnames";
export default function ReusableTiles({ pale }) {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { id: { eq: "second-reusable-tiles" } }) {
        frontmatter {
          tiles {
            title
            subtitle
            link
            tag
            accent
            imageOnly
            image {
              publicURL
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className={pale ? "bg-pale-100" : ""}>
      {" "}
      <div className="container  pt-8 pb-4">
        <div className="row collapsed justify-center flex-wrap  ">
          {has(data, "markdownRemark.frontmatter.tiles[0]") &&
            data.markdownRemark.frontmatter.tiles.map((tile) => {
              return (
                <div className="col mb-4 max-w-full">
                  <Link
                    to={tile.link}
                    css={css`
                      width: 320px;
                      height: 140px;
                      /* @sm */
                      @media (max-width: 350px) {
                        height: auto;
                      }
                    `}
                    className={cn(
                      "rounded-lg overflow-hidden flex items-stretch max-w-full mx-2",

                      {
                        "text-primary": !tile.accent,
                      },
                      {
                        "bg-accent text-white": tile.accent,
                      }
                    )}
                  >
                    <div className="flex-grow w-1/2">
                      {has(tile, "image.childImageSharp.fluid") && (
                        <Image
                          className="h-full"
                          fluid={tile.image.childImageSharp.fluid}
                        ></Image>
                      )}
                    </div>
                    {!tile.imageOnly && (
                      <div className="flex-shrink-0 w-1/2  p-2">
                        <span
                          className={cn(
                            "text-sm font-bench px-2 rounded uppercase",
                            {
                              "bg-white text-accent": tile.accent,
                            },
                            { "bg-primary text-white": !tile.accent }
                          )}
                        >
                          {tile.tag}
                        </span>
                        <h3 className="font-slab font-bold pl-0 leading-tight mt-3">
                          {tile.title}
                        </h3>
                        <p className="opacity-75">{tile.subtitle}</p>
                      </div>
                    )}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
